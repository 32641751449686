import React from "react"
import Image from "../components/image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-grid-system';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,faChevronRight,faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const portfolio = { 
    "alpro": {"title":"Alpro - Einfach nur Urlaub", "type": "Product", "url":"alpro.mp4"},
    "lupberger": {"title":"Lupberger Aluminium", "type": "Industry", "url":"lupberger.mp4"},
    "corona": {"title":"Corona - Kinder Stärken", "type": "Social", "url":"corona.mp4"},
    "profour": {"title":"Profour - Einfach stark!", "type": "Product", "url":"profour.mp4"},
    "zff": {"title":"ZFF - Dein Held", "type": "Creative", "url":"zff.mp4"},
    /*
    "insta": {"title":"ZFF Insta - Känguru", "type": "Creative", "url":"insta.mp4"},
    "dont_blink": {"title":"ZFF Insta - Don't blink", "type": "Creative", "url":"dont_blink.mp4"},
    "mma": {"title":"MMA - Arendsee", "type": "Imagefilm", "url":"mma.mp4"},
    */
    "uebergabe": {"title":"Endlich digitalisieren", "type": "Kampagne", "url":"uebergabe.mp4"},
};
console.log(portfolio)


class Page extends React.Component {
    constructor(props) {
        super(props)
        this.state = { visible: false, current_slug: "alpro" }
    }
    render() {
        return (
            <Layout container={false}>
                <SEO title="Film Portfolio" />

                <div className={this.state.visible ? "fullscreen fullscreen-visible" : "fullscreen fullscreen-hidden"}>
                    <img style={{ right: 0, position: "absolute", margin: "40px" }} onClick={() => { this.setState({ visible: false }) }} className="rotate-when-hidden" src={require("../images/portfolio/close.jpg")} />
                    <div style={{ fontSize: "1.4em" }} className="mt-4 text-center">
                        <h1 className="primary">{portfolio[this.state.current_slug].title}</h1>
                        <p class="muted">{portfolio[this.state.current_slug].type}</p>
                    </div>
                    <div className="portfolio-big text-center">
                        <img className="bg" src={require("../images/portfolio/imac.jpg")} />
            { this.state.visible &&  <video controls autoPlay src={require("../images/videos/" + this.state.current_slug + ".mp4")} 
                className="img" />
}
                    </div>
                    <p style={{ fontSize: "1.8em" }} class="text-center muted">{portfolio[this.state.current_slug].url.split("//")[1]}</p>
                </div>


                <div className="page-header">
                    <h1 className="text-center mt-4 mb-1">Film Portfolio</h1>
                    <p className="text-center">Beispielvideos</p>
                </div>

                <img style={{ width: "100%", position: "absolute" }} src={require("../images/divider/divider2.svg")}></img>

                <Container className="portfolio-container">
                    <Row className="portfolio-row">
                        {
                            Object.keys(portfolio).map(slug => {
                                let site = portfolio[slug]
                                console.log(site)
                                return <Col key={slug} className="portfolio text-center" lg={4}>
                                    <div onClick={() => { this.setState({ visible: true, current_slug: slug }) }} className="portfolio-imac">
                                        <img src={require("../images/videos/images/" + slug + "_350.jpg")} className="portfolio-img" />
                                    </div>
                                    <h2>{site.title}</h2>
                                    <p>{site.type}</p>
                                </Col>

                            })
                        }

                    </Row>
                </Container>


                <div style={{textAlign:"center"}}>
                    <a href="https://dominik.ziegenhagel.com/filme.html" className="btn btn-primary-outline btn-lg" style={{marginTop:"40px",marginBottom:"90px",textAlign:"center",display:"inline-block"}}>
                        Komplettes Portfolio ansehen &emsp;<FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </a>
                </div>

            </Layout>
        )
    }
}

export default Page
